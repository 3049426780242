<template>
  <section v-if="data">
    <PagesIndexComponent :data="data" />
  </section>
  <SharedErrorComponent v-else-if="error" :error="error" />
</template>

<script lang="ts" setup>

const i18nActiveIso = baseI18nGetActiveIso()

const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryHomePage( i18nActiveIso ) } }
  )
})

basePageSetHead(data)
</script>
