<template>
  <div
    :class="$style.root"
  >
    <i18nLink
      :to="routeObject"
      :class="$style.link"
    >
      <CoreImageComponent
        v-if="coverImage"
        :image="coverImage"
        :class="$style.image"
        :style="{
          '-webkit-mask-image': 'url(' + maskImage + ')'
        }"
      />
    </i18nLink>
  </div>
</template>

<script lang="ts" setup>
import { sample, range } from 'lodash-es'

const mouseEntered = ref(false)

const props = defineProps({
  data: Object
})

const slide = baseStrapiGetAttributes(props?.data)

const coverImage = slide?.coverImage?.data?.attributes

const routeName = strapiGetRouteReference(props?.data?.key)?.name
const routeObject = {
  name: routeName,
  params: { slug: slide?.slug }
}

const maskImages = range(1, 16).map((i) => `svg/gg-mask-${i}.svg`)
const maskImage = computed(() => {
  return sample(maskImages)
})
</script>

<style module lang="scss">
.root {
  position: relative;

  width: 100%;
  height: 100%;

  @media (max-width: $breakpoint-s) {
    pointer-events: none;
  }
}

.image {
  --base--image--height: var(--100vh);
  --base--image--object-fit: cover;
  mask-size: 100vw;
  mask-position: center;
  mask-repeat: no-repeat;
}

.root[data-full] .image,
.root[data-last-slide] .image {
  mask-image: none !important;
}
</style>
