<template>
  <div
    :class="$style.root"
    v-if="data"
  >
    <div :class="$style.linkContainer">
      <i18nLink
        :to="routeObject"
        :class="$style.link"
      >
        <div
          @mouseenter="$emit('setSlideId', data?.value?.id)"
          @mouseleave="$emit('setSlideId', null)"
          :class="$style.title"
          :data-inverted="attributes?.invertTextOnHomepage === true ? '' : null"
        >
          {{ attributes?.title }}
          <div :class="$style.label">{{ $t(`${routeName}.label`) }}</div>
        </div>
      </i18nLink>
    </div>

    <div>
      {{ slideIndex }}/{{ slideLength }}
    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps({
  data: Object,
  slideIndex: Number,
  slideLength: Number
})

const attributes = computed(() => baseStrapiGetAttributes(props?.data))

const routeName = computed(() => strapiGetRouteReference(props?.data?.key)?.name)
const routeObject = computed(() => {
  return {
    name: routeName.value,
    params: { slug: attributes?.value?.slug }
  }
})
</script>

<style module>
.root {
  composes: font-size-large from global;

  margin-top: var(--unit--text-seperator);

  padding-left: var(--unit--default);
  padding-right: var(--unit--default);

  display: flex;
  gap: var(--unit--default);
}

.linkContainer {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.link {
  composes: reset-link from global;
}

.link:hover,
.link:hover * {
  color: var(--color--magenta);
}

.label {
  composes: font-size-medium from global;
  color: var(--color--dark-blue);
}
</style>
