import qs from 'qs'

const fields = [
  'title',
  'richText'
]

const slides = {
  slides: {
    populate: {
      bulletinBoardEntry: {
        fields: ['title', 'slug', 'invertTextOnHomepage'],
        populate: {
          coverImage: '*',
          invertTextOnHomepage: '*'
        }
      },
      calendarEntry: {
        fields: ['title', 'slug', 'invertTextOnHomepage'],
        populate: {
          coverImage: '*',
          invertTextOnHomepage: '*'
        }
      },
      projectEntry: {
        fields: ['title', 'slug', 'invertTextOnHomepage'],
        populate: {
          coverImage: '*',
          invertTextOnHomepage: '*'
        }
      }
    }
  },
}

export const queryHomePage = (locale: QueryLocaleString) => {
  const query = qs.stringify(
    {
      locale: locale || 'en-US',
      fields: fields,
      populate: {
        ...queryFragmentSeo,
        ...slides,
        localizations: {
          fields: fields,
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `home-page?${query}`
}
